<template>
  <div class="component">
    <van-popup v-model="show" round  position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="自定义提醒时间"
          :min-date="date"
          @cancel="show = false"
          @confirm="choseDate"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      currentDate: '',
      date: new Date(),
      index: ""
    }
  },
  watch: {},
  methods: {
    $show(i){
      this.show = true
      if(i+1){
        this.index = i
      }
    },
    choseDate(e) {
      const year = e.getFullYear();
      const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1);    //js从0开始取
      const date1 = (e.getDate()) < 10 ? '0' + (e.getDate()) : (e.getDate());
      const hour = (e.getHours()) < 10 ? '0' + e.getHours() : e.getHours();
      const minutes = (e.getMinutes()) < 10 ? '0' + e.getMinutes() : e.getMinutes();
      this.show = false
      const dateTime =  {
        date: year + "-" + month +"-" + date1,
        time: hour + ":" +minutes,
        i:this.index
      }
      this.$emit('change',dateTime)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-action-sheet__header{
  font-weight: bolder;
}
.component {
}
</style>
