<template>
  <div class="popup">
    <a-modal
        title="选择成员"
        :visible="visible"
        okText="确定"
        cancelText="取消"
        @cancel="visible = !visible"
        @ok="ok"
        width="280px"
    >
      <div class="search">
        <a-input-search placeholder="请输入昵称" style="width: 200px" @search="onSearch"/>
      </div>
      <div class="list">
        <div class="item" v-for="v in list">
          <div class="info">
            <div class="avatar">
              <img :src="v.avatar">
            </div>
            <div class="nickname">
              {{ v.name }}
            </div>
          </div>
          <div class="input">
            <a-checkbox v-model="v.select"/>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {getEmployeeList, getRoomOwnerList} from "../../../api/common";

export default {
  data() {
    return {
      visible: false,
      list: [],
      oldList: []
    }
  },
  methods: {
    onSearch(e) {
      if (!e) this.list = this.oldList;

      this.list = this.oldList.filter(v => {
        const rep = new RegExp(e);

        return rep.test(v.name);
      })
    },
    ok() {
      const data = this.list.filter(v => {
        return v.select;
      });

      this.visible = false;

      this.$emit('change', JSON.parse(JSON.stringify(data)));
    },
    show(room = false) {
      room ? this.getRoomData() : this.getData();

      this.visible = true;
    },
    getRoomData() {
      getRoomOwnerList().then(res => {
        res.data.forEach(v => v.select = false)

        this.list = res.data;
      })
    },
    getData() {
      getEmployeeList().then(res => {
        res.data.forEach(v => v.select = false)

        this.list = res.data;
        this.oldList = res.data;
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  height: 300px;
  overflow: auto;
}

.search {
  width: 100%;
  margin-bottom: 20px;

  /deep/ .ant-input-search {
    width: 100% !important;
  }
}

.list {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    .info {
      display: flex;
      align-items: center;
    }

    .avatar {
      img {
        width: 35px;
        height: 35px;
        border-radius: 3px;
        margin-right: 6px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
