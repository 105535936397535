<template>
  <div class="index">
    <div class="top-part">
      <div class="top-info">
        <div class="time-tips fs24 van-ellipsis">
          <div class="time-tips-box"><span @click="authInfo">{{ userDatalt.good }}，{{ userDatalt.name }} <span class="time-tips-role">- <van-icon name="user-o" />{{ userDatalt.roleName }}</span></span><!----></div>
        </div>
        <!--        <div class="icon-message">&lt;!&ndash;&ndash;&gt;</div>-->
        <div class="time-detail fs14" style="color: #fff">{{ date }}，{{ week }}</div>
      </div>
      <div class="customer-data">
        <div class="data-item">
          <div class="customer-label"><span class="fs14">我的客户</span></div>
          <div class="customer-value fs24">
            <countTo
              :startVal="0" 
              :endVal="baseInfo.total"
            ></countTo>
          </div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">今天新增</span></div>
          <div class="customer-value fs24 center">
            <countTo
              :startVal="0" 
              :endVal="baseInfo.todayAdd"
            ></countTo>
          </div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">昨日新增</span></div>
          <div class="customer-value fs24 center">
            <countTo
              :startVal="0" 
              :endVal="baseInfo.yesterdayAdd"
            ></countTo>
          </div>
        </div>
        <!--  -->
        <template v-if="userDatalt.dataPermission < 2">
          <div class="data-item">
            <div class="customer-label"><span class="fs14">部门客户</span></div>
            <div class="customer-value fs24">
              <countTo
                :startVal="0" 
                :endVal="baseInfo.departmentTotal"
              ></countTo>
            </div>
          </div>
          <div class="data-item">
            <div class="customer-label"><span class="fs14">今天新增</span></div>
            <div class="customer-value fs24 center">
              <countTo
                :startVal="0" 
                :endVal="baseInfo.departmentTodayAdd"
              ></countTo>
            </div>
          </div>
          <div class="data-item">
            <div class="customer-label"><span class="fs14">昨日新增</span></div>
            <div class="customer-value fs24 center">
              <countTo
                :startVal="0" 
                :endVal="baseInfo.departmentYesterdayAdd"
              ></countTo>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="main-content">
      <div style="padding-bottom: 6px;" class="tools-list">
        <div @click="routerPush('/contactStatistic/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/tongji.png"
              alt=""></div>
          <span class="fs12 tools-name">拉新统计</span>
        </div>
        <!-- 管理员 -->
        <template v-if="userDatalt.dataPermission < 2">
          <div class="tools-item" @click="routerPush('/employeeRanking/index')">
            <div class="img-box"><img
                src="../../assets/images/paihang.png"
                alt=""></div>
            <span class="fs12 tools-name">销售排行</span>
          </div>
          <div class="tools-item" @click="routerPush('/departmentLoss/index')">
            <div class="img-box"><img
                src="../../assets/images/liushi.png"
                alt=""></div>
            <div class="fs12 tools-name">企业流失</div>
          </div>
          <div class="tools-item" @click="routerPush('/sensitiveWordMonitor/index')">
            <div class="img-box"><img
                src="../../assets/images/fengkong.png"
                alt=""></div>
            <div class="fs12 tools-name">企业风控</div>
          </div>
        </template>
        <!-- 员工 -->
        <template v-else>
          <div class="tools-item" @click="routerPush('/workMoment/index')">
            <div class="img-box"><img
                src="../../assets/images/pengyouquan.png"
                alt=""></div>
            <span class="fs12 tools-name">历史朋友圈</span>
          </div>
          <div class="tools-item" @click="routerPush('/contactLost/index')">
            <div class="img-box"><img
                src="../../assets/images/liushi.png"
                alt=""></div>
            <div class="fs12 tools-name">流失客户</div>
          </div>
          <div class="tools-item" @click="routerPush('/contactDeleteRemind/index')">
            <div class="img-box"><img
                src="../../assets/images/tixing.png"
                alt=""></div>
            <div class="fs12 tools-name">删人提醒</div>
          </div>
        </template>
        <!-- <div @click="routerPush('/channelCode/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/huoma.png"
              alt=""></div>
          <span class="fs14 tools-name">渠道活码</span>
        </div>
        <div @click="routerPush('/contactWelcome/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/huanyingyu.png"
              alt=""></div>
          <span class="fs14 tools-name">欢迎语</span>
        </div> -->
      </div>

      <div class="type-entrance">
        <!-- 待审核 -->
        <div class="type-entrance-item stageAudit" v-if="userDatalt.dataPermission < 2 && baseInfo.stageAuditAmount">
          <div class="title">
            <div><van-icon name="warning-o" color="#ee0e24" /> 您有<span style="color: #ee0e24">{{ baseInfo.stageAuditAmount }}</span>条客户意向等级审批待处理</div>
            <div class="more">
              <span @click="routerPush('/stageAudit/index')">查看详情</span> 
            </div>
          </div>
        </div>
        <!-- 待办入口 -->
        <div class="type-entrance-item matter">
          <div class="title">
            <div>
              待办事项 <span></span>
            </div>
            <van-button style="height: 24px; border-radius: 25px; margin-left: 15px;" @click="routerPush('/index/create')" type="info">新建</van-button>
            <div class="more">
              <span @click="routerPush('/contactTodo/index')"><van-icon name="bars" /> 查看更多</span> 
            </div>
          </div>
          <div class="list" v-if="previewList.todo.length > 0">
            <div class="list-item" v-for="(item, index) in previewList.todo" :key="index">
              <div class="list-item-title">
                <img src="../../assets/images/date.png">
                {{ item.content }}
              </div>
              <div class="list-item-time">
                {{ item.createdAt }}
              </div>
            </div>
          </div>
          <van-empty v-else-if="!isLoading" :image="require('../../assets/images/mobiletodo.png')" description="没有待开始的待办~"/>
          <div class="loading" v-else>
            <a-spin/>
          </div>
          <!-- <van-empty :image="require('../../assets/images/mobiletodo.png')" description="没有待开始的待办~"/> -->
        </div>
        <!-- 待跟进客户 -->
        <div class="type-entrance-item waitList">
          <div class="title">
            <div>
              待跟进客户 <span></span>
            </div>
            <div class="more">
              <span @click="routerPush('/contactFollow/waitList?employee_id=' +userDatalt.id)"><van-icon name="bars"/> 查看更多</span> 
            </div>
          </div>
          <div class="list" v-if="waitListThree.length > 0">
            <div class="list-item" v-for="(item, index) in waitListThree" :key="index">
              <div class="list-item-time">
                
              </div>
              <div class="list-item-user">
                <van-image
                  radius="6"
                  width="40"
                  height="40"
                  :src="item.avatar"
                />
                <div>
                  {{ item.name }}
                  <div class="time">最后跟进：{{ item.last_follow_time }}</div>  
                </div>
                <div class="hand">
                  <van-button size="mini" @click="pushUser(item.contact_id)">跟进</van-button>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <!-- SOP入口 -->
        <div class="type-entrance-item sop">
          <div class="title">
            <div>
              客户SOP跟进 <span></span>
            </div>
            <div class="more">
              <span @click="routerPush('/contactSop/index')"><van-icon name="bars" /> 查看更多</span> 
            </div>
          </div>
          <div class="list" v-if="previewList.contactSopLog.length > 0">
            <div class="list-item" v-for="(item, index) in previewList.contactSopLog" :key="index">
              <div class="list-item-user" v-if="item.contact">
                <van-image
                  radius="6"
                  width="40"
                  height="40"
                  :src="item.contact.avatar"
                />
                <div>{{ item.contact.name }}</div>
                <div class="hand">
                  <span @click="onChat(item.contact.wxExternalUserid)"><van-icon name="chat-o" /> 打开聊天</span>
                </div>
              </div>
              <div class="list-item-user" v-else>
                <van-image
                  radius="6"
                  width="40"
                  height="40"
                />
                <div>无用户数据</div>
              </div>
              <div class="task" v-for="(task, taskIndex) in item.task.content" :key="taskIndex">
                <div v-if="task.type == 'text'">{{ task.value }}</div> 
                <div v-if="task.type == 'image'" class="img">
                  <img :src="task.value"> 
                  <div>长按图片转发</div>
                </div>
                <div class="hand">
                  <van-button v-if="task.type == 'text'" size="mini" @click="copyData(task.value)">复制</van-button>
                </div> 
              </div>
            </div>
          </div>
          <van-empty v-else-if="!isLoading" :image="require('../../assets/images/mobiletodo.png')" description="没有需要跟进的客户~"/>
          <div class="loading" v-else>
            <a-spin/>
          </div>
        </div>
        <!-- 沉默入口 -->
        <div class="type-entrance-item silent">
          <div class="title">
            <div>
              沉默客户跟进 <span></span>
            </div>
            <div class="more">
              <span @click="routerPush('/silenceSop/index')"><van-icon name="bars"/> 查看更多</span> 
            </div>
          </div>
          <div class="list" v-if="previewList.silenceSopLog.length > 0">
            <div class="list-item" v-for="(item, index) in previewList.silenceSopLog" :key="index">
              <div class="list-item-time">
                <div>
                  <img style="margin-right: 8px; width: 18px" src="../../assets/images/clock.png"> 提醒时间：{{ item.notice_time }}
                </div>
              </div>
              <div class="list-item-user">
                <van-image
                  radius="6"
                  width="40"
                  height="40"
                  :src="item.contact.avatar"
                />
                <div>
                  {{ item.contact.name }}
                  <div class="time">最后跟进:{{ item.last_follow_time }}</div>  
                </div>
                <div class="hand">
                  <van-button size="mini" @click="pushUser(item.contact.id)">跟进</van-button>
                </div> 
              </div>
              <!-- <div class="list-item-user" v-for="(user, userIndex) in item.silence_contact" :key="userIndex">
                <template v-if="user">
                  <van-image
                    radius="6"
                    width="40"
                    height="40"
                    :src="user.avatar"
                  />
                  <div>{{ user.name }}</div>
                  <div class="hand">
                    <van-button size="mini" @click="pushUser(user)">跟进</van-button>
                  </div> 
                </template>
                <template v-else>
                  <van-image
                    radius="6"
                    width="40"
                    height="40"
                  />
                  <div>无用户数据</div>
                </template>
              </div> -->
            </div>
          </div>
          <van-empty v-else-if="!isLoading" :image="require('../../assets/images/mobiletodo.png')" description="没有需要跟进的客户~"/>
          <div class="loading" v-else>
            <a-spin/>
          </div>
        </div>
      </div>
    </div>
    
    <van-tabbar v-model="active" :safe-area-inset-bottom="true" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home.png"/>
          <img v-else src="../../assets/images/home-active.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png"/>
          <img v-else src="../../assets/images/customer.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>运营工具</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/function-active.png"/>
          <img v-else src="../../assets/images/function.png">
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png"/>
          <img v-else src="../../assets/images/my.png">
        </template>
      </van-tabbar-item> -->
    </van-tabbar>
    <van-dialog v-model="showAuthInfo" confirm-button-color="#1989fa" theme="round-button" confirm-button-text="授权获取成员资料" @confirm="authInfo">
      <div style="padding: 20px" >
        <img src="../../assets/images/auth_bg.png" width="100%"/>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {workEmployee, updateStatus, userCenter, contactTodoList} from "../../api/homePage"
import { ContactFollowApi } from '@/api/contactFollow'
import countTo from "vue-count-to"
import { mapActions } from 'vuex'
import { Dialog, Toast } from 'vant'
import { getCookie, pushUser } from '@/plugins/utils'
import { openEnterpriseChat, setClipboardData } from '@/utils/wxCodeAuth'
export default {
  [Dialog.Component.name]: Dialog.Component,
  components: {
    countTo
  },
  data() {
    return {
      pushUser,
      showAuthInfo: false,
      schedule: 'have-matter-wait',
      // 默认日期
      week: '',
      date: '',
      // 列表展示日期
      listDate: '',
      // 数据类别加载动画
      isLoading: true,
      // tab 切换
      active: 0,
      // 顶部预览数据
      baseInfo: {
        total: 0,
        todayAdd: 0,
        yesterdayAdd: 0,
        departmentTotal: 0,
        departmentTodayAdd: 0,
        departmentYesterdayAdd: 0
      },
      previewList: {
        todo: [],
        silenceSopLog: [],
        contactSopLog: []
      },
      userDatalt: {
        name: '',
        good: ''
      },
      waitListThree: []
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      'setUesrIPermission'
    ]),
    // pushUser(item) {
    //   var url = 'https://work.' + location.hostname.split(".")[1] + '.' + location.hostname.split(".")[2] + '/contact?agentId=' + getCookie('workbench_agentId') + '&contactId='+item.id
    //   window.location.href = url
    // },
    async copyData (value) {
      Toast('复制成功')
      await setClipboardData(value)
    },
    async onChat (wxId) {
      console.log(wxId)
      await openEnterpriseChat('', wxId)
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    // 获取数据
    getNowTime(e) {
      this.isLoading = true
      workEmployee({day: e}).then(res => {
        this.baseInfo = res.data.baseInfo
        this.previewList.todo = res.data.todo
        this.previewList.silenceSopLog = res.data.silenceSopLog
        
        this.previewList.contactSopLog = res.data.contactSopLog
        for (var i = 0; i < this.previewList.contactSopLog.length; i++) {
          this.previewList.contactSopLog[i].task = JSON.parse(this.previewList.contactSopLog[i].task)
        }
        console.log(this.previewList)
        if (res.data.stageAuditAmount) {
          this.baseInfo.stageAuditAmount = res.data.stageAuditAmount
        }
        this.isLoading = false
      })
    },
    // 获取当前日期
    getWeekDate() {
      const now = new Date();
      const day = now.getDay();
      const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      const year = new Date().getFullYear();
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      this.week = weeks[day];
      this.date = mm + "月" + dd + "日"
      this.dateDetail = {
        year: year,
        month: mm,
        day: dd
      }
      this.listDate = '今天 · ' + mm + "月" + dd + "日" + "  " + weeks[day]

    },
    details(id) {
      this.$router.push({
        path: '/index/create',
        query: {
          id: id
        }
      })
    },
    userDetails() {

      userCenter().then(res => {
        this.userDatalt = res.data.baseInfo
        console.log(this.$store)
        // this.$store.dispatch('user/setUesrPermission',this.userDatalt.dataPermission)
        this.setUesrIPermission(this.userDatalt.dataPermission)
        const now = new Date()
        const hour = now.getHours()

        if (hour < 6) {
          this.userDatalt.good = "凌晨好"
        } else if (hour < 9) {
          this.userDatalt.good = "早上好"
        } else if (hour < 12) {
          this.userDatalt.good = "上午好"
        } else if (hour < 14) {
          this.userDatalt.good = "中午好"
        } else if (hour < 17) {
          this.userDatalt.good = "下午好"
        } else if (hour < 19) {
          this.userDatalt.good = "傍晚好"
        } else if (hour < 22) {
          this.userDatalt.good = "晚上好"
        } else {
          this.userDatalt.good = "夜里好"
        }

        if (!res.data.infoComplete) {
          this.showAuthInfo = true
          // Dialog.alert({
          //   allowHtml: true,
          //   message: '<img src="/img/auth_bg.png"/>',
          //   theme: 'round-button',
          //   confirmButtonColor: '#1989fa',
          // }).then(() => {
          //   // const { agentId } = this.$route.query
          //   // const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
          //   // window.location.href = apiBaseUrl + '/workbench/agent/infoAuth?agentId=' + agentId + '&target=' + encodeURIComponent(window.location.href)
          // })
        }
      })
    },
    authInfo () {
      const { agentId } = this.$route.query
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
      window.location.href = apiBaseUrl + '/workbench/agent/infoAuth?agentId=' + agentId + '&target=' + encodeURIComponent(window.location.href)
    },
    detailsee(i) {
      this.$router.push({
        path: "/contact/detail",
        query: {
          contactId: i.contactId
        }
      })
    }
  },
  created() {
    // 获取日期
    this.getWeekDate()
    // 获取数据
    this.getNowTime()
    // user信息
    this.userDetails()
    //
    // this.getContactTodo()
    // 待跟进客户列表3条
    ContactFollowApi.waitListThree().then(res => {
      this.waitListThree = res.data.list
    })
  }
}
</script>

<style lang="less" scoped>
@wait: #45c400;
@over: #c8cad0;
@finsh: red;
/deep/.van-tabbar{
  box-sizing: content-box !important;
}
/deep/.van-empty {
  padding: 20px 0;
}
/deep/.van-empty__image {
  width: 80px !important;
  height: 71px !important;
}
/deep/.van-empty__description {
  font-size: 12px;
  margin: 0.4rem;
}

.index {
  font-size: 14px;
  min-height: 100%;
  background-color: #f2f3f7;
  padding-bottom: 70px;

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .tab {
    img {
      width: 22px;
      height: 22px;
    }
  }

  .iconS {
    transform: rotate(180deg);
    color: #1890ff !important;
  }

  .top-part {
    // height: 290px;
    background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);
    padding: 16px 12px 0;
    position: relative;

    .top-info {
      padding: 0 12px 0 8px;

      .time-tips-box {
        display: flex;
        align-items: center;
      }

      .time-tips-role {
        font-size: 14px;
      }

      .time-tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 33px;
        font-weight: 500;
        color: #fff;
        // width: 252.5px;
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon-message {
        position: absolute;
        top: 25px;
        right: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(../../assets/images/news.png) no-repeat;
        background-size: 100% 100%;
      }

      .time-detail {
        color: #c2d7ff;
        margin-top: 4px;
        line-height: 20px;
      }
    }


    .customer-data {
      cursor: pointer;
      min-height: 108px;
      // background: url(../../assets/images/background.png) no-repeat 50%/contain;
      // background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 17px 0 20px;
      position: relative;
      top: 24px;
      color: #fff !important;

      background: linear-gradient(to bottom right, rgba(92, 157, 255, 0.5) 0%, rgba(106, 177, 255, 0.3) 50%, rgba(117, 195, 255, 0.3) 70%, rgba(77, 214, 255, 0.4) 100%);
      border-radius: 20px;

      .data-item {
        width: 33.33%;
        margin-bottom: 15px;
        text-align: center;

        .customer-label {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
          position: relative;
        }

        .customer-value {
          font-family: DINAlternateBold;
          font-weight: 700;
          color: #fff;
          line-height: 36px;
        }
      }
    }
  }
  

  .loading {
    width: 100%;
    height: 164px;
    /* background: #fff; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .main-content {
    background-color: #f2f3f7;
    // margin-top: -109px;
    z-index: 1;
    position: relative;
    border-radius: 4px 4px 0 0;

    .tools-list {
      display: flex;
      flex-direction: row;
      padding-top: 24px;

      .tools-item {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img-box {
          width: 30px;
          height: 30px;
          margin-bottom: 8px;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .tools-name {
          color: #545454;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .type-entrance {
      padding: 16px 12px;

      &-item {
        padding: 12px;
        margin-bottom: 12px;
        border-radius: 12px;
        box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 15px;
          font-weight: 600;
          // color: #fff;

          // >div {
          //   flex: 1;
          // }

          span {
            font-weight: 400;
          }

          .more {
            flex: 1;
            text-align: right;
            font-size: 14px;
          }
        }       

        // /deep/.van-empty__description {
        //   color: #f1f1f1;
        // }
      }

      &-item:last-child {
        margin-bottom: 0;
      }

      &-item.stageAudit {
        background: #fff;
        .title {
          margin: 0;
        }
      }

      &-item.matter {
        background: #fff;
        // background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);

        .list {
          &-item {
            padding: 16px;
            margin-bottom: 16px;
            background: #f2f3f7;
            border-radius: 12px;

            &-title {
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 6px;

              img {
                width: 18px;
                margin-right: 5px;
              }
            }

            &-time {
              font-size: 12px;
              color: #999;
            }
          }

          &-item:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-item.sop, &-item.waitList {
        background-color: #fff;
        // background: #ff4e6f;

        .list {
          &-item {
            padding: 12px;
            margin-bottom: 16px;
            background: #f2f3f7;
            border-radius: 12px;

            &-user {
              border-radius: 12px;
              font-size: 14px;
              display: flex;
              align-items: center;

              .van-image {
                margin-right: 8px;
              }

              img {
                vertical-align: middle;
              }

              .hand {
                flex: 1;
                text-align: right;
                color: #1989fa;
              }
            }

            .time {
              font-size: 12px;
            }

            .task {
              background: #fff;
              padding: 10px;
              border-radius: 12px;
              margin-top: 12px;
              display: flex;
              align-items: center;

              .img {
                font-size: 12px;
                color: #999;

                img {
                  max-height: 80px;
                  margin-bottom: 6px;
                }
              }

              .hand {
                flex: 1;
                text-align: right;
              }
            }
            
          }

          &-item:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-item.waitList {
        .list {
          &-item {
            margin-bottom: 16px;
            background: #f2f3f7;
            border-radius: 12px;
          }
        }
      }

      &-item.silent {
        background-color: #fff;
        // background: #ffbc00;

        .list {
          &-item {
            padding: 12px 12px 6px;
            margin-bottom: 16px;
            background: #f2f3f7;
            border-radius: 12px;

            &-user {
              background: #fff;
              padding: 10px;
              border-radius: 12px;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 6px;

              .van-image {
                margin-right: 8px;
              }

              img {
                vertical-align: middle;
              }

              .hand {
                flex: 1;
                text-align: right;
              }
            }

            &-time {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              font-size: 12px;
              color: #999;
            }

            .time {
              font-size: 12px;
            }
          }

          &-item:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
