<template>
  <div class="index">
    <div v-if="meeting">
      <van-nav-bar
          title="待办事项"
          left-text="返回"
          right-text="保存"
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
      />
      <div class="content">
        <div style="
    border-bottom: 1px solid #e5e6e9;">
          <div class="tabs">
            <p :class="{'tabs-active' : tabsChose == 0}" @click="tabsChoseActive(0)">定时提醒</p>
            <p :class="{'tabs-active' : tabsChose == 1}" @click="tabsChoseActive(1)">周期提醒</p>
          </div>
        </div>
        <div v-if="tabsChose == 0">
          <div class="tips">
            <img src="../../assets/images/tips.png">
            若当前提醒逾期，会触发自动延期规则，即之后的提醒时间也会相应的延期提醒
          </div>
          <div class="field">
            <van-field
                style="border-radius: 10px 10px 0 0;"
                v-model="message"
                rows="2"
                :autosize="{ maxHeight: 200, minHeight: 100 }"
                type="textarea"
                maxlength="200"
                placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
                show-word-limit
            />
            <div v-if="!user" class="add-user" @click="$refs.customer.$show()">
              <van-icon style="margin: 0 8px" name="add-o"/>
              添加客户
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;padding: 0 10px" v-else
                 class="add-user" @click="$refs.customer.$show()">
              <div style="font-size: 12px">
                <img style="margin-right: 5px;border-radius: 50%;" :src="user[0].avatar" alt="">
                {{ user[0].name }}
              </div>
              <van-icon style="font-size: 12px" name="arrow"/>
            </div>
            <van-divider style="margin-left:14px;margin-right: 14px;"/>
            <div class="operation" @click="$refs.time.$show()">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="calendar"/>
                提醒时间
              </div>
              <div style="color: #ccc">
                <span v-if="timing">{{ timing.date }} {{ timing.time }}</span>
                <span v-else>选择时间</span>
                <a-icon type="right"/>
              </div>
            </div>
          </div>

          <div v-for="(item,index) in timingList" class="field">
            <van-field
                style="border-radius: 10px 10px 0 0;"
                v-model="item.content"
                rows="2"
                :autosize="{ maxHeight: 200, minHeight: 100 }"
                type="textarea"
                maxlength="200"
                placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
                show-word-limit
            />
            <div class="operation" style="margin-top: 10px;">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="calendar"/>
                提醒时间
              </div>
              <div style="color: #ccc" @click="$refs.time.$show(index)">
                <span v-if="item.day">{{ item.day }} {{ item.time }}</span>
                <span v-else>选择时间</span>
                <a-icon type="right"/>
              </div>
            </div>
            <div class="insert">
              <div @click="addTiming">插入提醒</div>
              <div @click="deteleTiming(index)">删除</div>
            </div>
          </div>
          <div class="addTips" @click="addTiming">
            <van-icon name="add-o"/>
            添加提醒
          </div>
        </div>
        <div v-if="tabsChose == 1">
          <div class="field">
            <van-field
                style="border-radius: 10px 10px 0 0;"
                v-model="message"
                rows="2"
                :autosize="{ maxHeight: 200, minHeight: 100 }"
                type="textarea"
                maxlength="200"
                placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
                show-word-limit
            />
            <div v-if="!user" class="add-user" @click="$refs.customer.$show()">
              <van-icon style="margin: 0 8px" name="add-o"/>
              添加客户
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;padding: 0 10px" v-else
                 class="add-user" @click="$refs.customer.$show()">
              <div style="font-size: 12px">
                <img style="margin-right: 5px;border-radius: 50%;" :src="user[0].avatar" alt="">
                {{ user[0].name }}
              </div>
              <van-icon style="font-size: 12px" name="arrow"/>
            </div>

            <van-divider style="margin-left:14px;margin-right: 14px;"/>
            <div class="operation">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="calendar"/>
                提醒时间
              </div>
              <div style="color: #ccc" @click="$refs.time.$show()">
                <span v-if="timing">{{ timing.date }} {{ timing.time }}</span>
                <span v-else>选择时间</span>
                <a-icon type="right"/>
              </div>
            </div>
            <van-divider style="margin-left:14px;margin-right: 14px;    margin-top: 5px;"/>
            <div class="operation">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="reload"/>
                设置重复
              </div>
              <div v-if="!repeats" @click="$refs.settings.$show()" class="van-ellipsis" style="color: #ccc;max-width: 137px;display: flex;
    align-items: center;">
                选择重复
                <a-icon style="float: right" type="right"/>
              </div>
              <div v-else class="van-ellipsis" @click="$refs.settings.$show()" style="color: #ccc;max-width: 137px;
    align-items: center;">
                {{ repeats.repeat.name }},{{ repeats.endRepeat.title }}
              </div>
            </div>
            <div class="details-title" v-if="repeats">{{ repeats.repeat.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="meetingT">
      <van-nav-bar
          title="添加会议"
          left-text="返回"
          right-text="确定"
          left-arrow
      />
      <div class="content">
        <div class="field">
          <van-field
              style="border-radius: 10px 10px 0 0;"
              v-model="message"
              rows="2"
              :autosize="{ maxHeight: 200, minHeight: 100 }"
              type="textarea"
              maxlength="200"
              placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
              show-word-limit
          />
          <div class="add-user">
            <van-icon style="margin: 0 8px" name="add-o"/>
            参会同事
          </div>
          <div class="add-user" style="margin-top: 7px" @click="$refs.customer.$show()">
            <van-icon style="margin: 0 8px" name="add-o"/>
            添加客户
          </div>
          <van-divider style="margin: 10px 14px;"/>
          <div class="operations" style="margin-bottom: 0!important;">
            <div>
              <img style="width: 16px;height: 16px;margin-right: 5px" src="../../assets/images/meeting_time.png"/>
              会议时间
            </div>
            <div @click="$refs.time.$show()" style="color: #ccc">
              选择时间
              <a-icon type="right"/>
            </div>
          </div>
          <van-divider style="margin: 10px 14px;"/>
          <div class="operations">
            <div>
              <img style="width: 16px;height: 16px;margin-right: 5px" src="../../assets/images/meeting_clock.png"/>
              提醒时间
            </div>
            <div @click="$refs.time.$show()" style="color: #ccc">
              选择时间
              <a-icon type="right"/>
            </div>
          </div>
          <van-divider style="margin: 10px 14px;"/>
          <div style="padding-bottom: 10px;" class="operations">
            <div style="flex: 1;display: flex;align-items: center">
              <img style="width: 16px;height: 16px;margin-right: 5px" src="../../assets/images/meeting_clock.png"/>
              <van-field style="padding: 0" size="small" v-model="value" placeholder="请输入用户名"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="edit">
      <van-nav-bar
          title="待办事项"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
      >
        <template #right>
          <div @click="remove" style="color: #1890ff;margin-right: 10px;" href="">删除</div>
          <div v-if="stats != 3" @click="onClickRight" style="color: #1890ff" href="">保存</div>
        </template>
      </van-nav-bar>
      <div class="content">
        <div v-if="tabsChose == 0">
          <div style="margin: 0;" class="tips">
            <img src="../../assets/images/tips.png">
            若当前提醒逾期，会触发自动延期规则，即之后的提醒时间也会相应的延期提醒
          </div>
          <div class="field">
            <van-field
                style="border-radius: 10px 10px 0 0;"
                v-model="message"
                rows="2"
                :autosize="{ maxHeight: 200, minHeight: 100 }"
                type="textarea"
                maxlength="200"
                placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
                show-word-limit
            />
            <div v-if="!user" class="add-user" @click="$refs.customer.$show()">
              <van-icon style="margin: 0 8px" name="add-o"/>
              添加客户
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;padding: 0 10px" v-else
                 class="add-user">
              <div style="font-size: 12px">
                <img style="margin-right: 5px;border-radius: 50%;" :src="user[0].avatar" alt="">
                {{ user[0].name }}
              </div>
              <van-icon style="font-size: 12px" name="arrow"/>
            </div>
            <van-divider style="margin-left:14px;margin-right: 14px;"/>
            <div class="operation" @click="$refs.time.$show()">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="calendar"/>
                提醒时间
              </div>
              <div style="color: #ccc">
                <span v-if="timing">{{ timing.date }} {{ timing.time }}</span>
                <span v-else>选择时间</span>
                <a-icon type="right"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tabsChose == 1">
          <div class="field">
            <van-field
                style="border-radius: 10px 10px 0 0;"
                v-model="message"
                rows="2"
                :autosize="{ maxHeight: 200, minHeight: 100 }"
                type="textarea"
                maxlength="200"
                placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦！"
                show-word-limit
            />
            <div v-if="!user" class="add-user" @click="$refs.customer.$show()">
              <van-icon style="margin: 0 8px" name="add-o"/>
              添加客户
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;padding: 0 10px" v-else
                 class="add-user">
              <div style="font-size: 12px">
                <img style="margin-right: 5px;border-radius: 50%;" :src="user[0].avatar" alt="">
                {{ user[0].name }}
              </div>
              <van-icon style="font-size: 12px" name="arrow"/>
            </div>

            <van-divider style="margin-left:14px;margin-right: 14px;"/>
            <div class="operation">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="calendar"/>
                提醒时间
              </div>
              <div style="color: #ccc" @click="$refs.time.$show()">
                <span v-if="timing">{{ timing.date }} {{ timing.time }}</span>
                <span v-else>选择时间</span>
                <a-icon type="right"/>
              </div>
            </div>
            <van-divider style="margin-left:14px;margin-right: 14px;    margin-top: 5px;"/>
            <div class="operation">
              <div>
                <a-icon style="color: #1890ff;margin-right: 5px;" type="reload"/>
                设置重复
              </div>
              <div v-if="!repeats" @click="$refs.settings.$show()" class="van-ellipsis" style="color: #ccc;max-width: 137px;display: flex;
    align-items: center;">
                选择重复
                <a-icon style="float: right" type="right"/>
              </div>
              <div v-else class="van-ellipsis" @click="$refs.settings.$show()" style="color: #ccc;max-width: 137px;
    align-items: center;">
                {{ repeats.repeat.name }},{{ repeats.endRepeat.title }}
              </div>
            </div>
            <div class="details-title" v-if="repeats">{{ repeats.repeat.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  時間選擇  -->
    <addTime ref="time" @change="time"></addTime>
    <!--  选择客户  -->
    <addCustomer ref="customer" @change="choseCustomer"/>
    <!--  重复选择  -->
    <duplicateSettings ref="settings" @change="settingsChose"/>
    <!--  员工选择  -->
    <popup ref="popup"/>
  </div>
</template>

<script>
import addCustomer from "./components/addCustomer";
import addTime from "./components/addTime";
import duplicateSettings from "./components/duplicateSettings";
import popup from "../../components/select/employee/popup";
import {store, contactTodo, update, destroy} from "../../api/homePage"
import {Dialog} from 'vant';

export default {
  components: {
    addCustomer,
    addTime,
    duplicateSettings,
    popup
  },
  data() {
    return {
      // tbas切换
      tabsChose: 0,
      // 時間選擇
      // 添加方式
      meeting: true,
      meetingT: false,
      //选择客户
      user: '',
      // 文字
      message: '',
      // 定时提醒时间
      timing: '',
      // 定时提醒多个
      timingList: [],
      // algin
      repeats: '',

      // 编辑 删除
      edit: '',
      stats: ''
    }
  },
  watch: {},
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    // 类型切换
    tabsChoseActive(i) {
      this.tabsChose = i

      this.user = ''
      // 文字
      this.message = ''
      // 定时提醒时间
      this.timing = ''
      // 定时提醒多个
      this.timingList = []
    },
    // 选择客户
    choseCustomer(e) {
      this.user = e
    },
    // 选择时间
    time(e) {
      if (typeof (e.i) === 'number') {
        this.timingList[e.i].day = e.date
        this.timingList[e.i].time = e.time
      } else {
        this.timing = e
      }
    },
    // 添加提醒
    addTiming() {
      this.timingList.push({
        content: '',
        day: '',
        time: ''
      })
    },
    // 删除提醒
    deteleTiming(index) {
      if (index == 0) {
        this.timingList.splice(index, 1)
      } else {
        this.timingList.splice(index, 1)
      }
    },
    // 获取重复信息
    settingsChose(data) {
      this.repeats = data
    },
    // 编辑修改
    editShow() {
      contactTodo({
        todoId: this.id
      }).then(res => {
        this.stats = res.data.status
        if (res.data.type - 1 == 0) {
          this.tabsChose = res.data.type - 1
          this.message = res.data.content
          this.user = [res.data.contactInfo]
          this.timing = {
            date: res.data.day,
            time: res.data.time
          }
        } else {
          this.tabsChose = res.data.type - 1
          this.message = res.data.content
          this.user = [res.data.contactInfo]
          this.timing = {
            date: res.data.day,
            time: res.data.time
          }
          this.repeats = {
            repeat: res.data.repeat,
            endRepeat: res.data.endRepeat
          }
        }
      })
    },
    // 删除
    remove() {
      Dialog.confirm(
          {
            title: '提示',
            message:
                '确定要删除吗？',
            confirmButtonColor: '#1890ff'
          }
      ).then(() => {
            destroy({
              todoId: this.id
            }).then(res => {
              this.$router.back()
              this.$message.success('删除成功')
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    // 新建
    onClickRight() {
      let error = false
      if (!this.user) {
        this.$message.warning('请选择员工')
        return
      } else if (!this.timing.date) {
        this.$message.warning('请选择时间2')
        return
      } else if (!this.message) {
        this.$message.warning('请输入提醒事件')
        return
      }
      if (!this.edit) {
        if (this.tabsChose == 0) {
          this.timingList.map(item => {
            if (!item.content) {
              this.$message.warning('请输入提醒事件')
              error = true
            } else if (!item.day) {
              this.$message.warning('请选择时间1')
              error = true
            }
          })
          if (error) return
          const imList = [...this.timingList]
          imList.unshift({
            content: this.message,
            day: this.timing.date,
            time: this.timing.time
          })
          const data = {
            type: 1,
            contactId: this.user[0].id,
            timingRemind: imList
          }
          store(data).then(res => {
          })
        } else {
          const repeat = this.repeats.repeat
          const endRepeat = this.repeats.endRepeat
          const data = {
            type: 2,
            contactId: this.user[0].id,
            cycleRemind: {
              content: this.message,
              day: this.timing.date,
              time: this.timing.time,
              repeat,
              endRepeat
            }
          }
          store(data).then(res => {
          })
        }
        this.$router.back()
        this.$message.success('新建成功')
      } else {
        if (this.tabsChose == 0) {
          this.timingList.map(item => {
            if (!item.content) {
              this.$message.warning('请输入提醒事件')
              error = true
            } else if (!item.day) {
              this.$message.warning('请选择时间1')
              error = true
            }
          })
          ()
          if (error) return
          const imList = [...this.timingList]
          imList.unshift({
            content: this.message,
            day: this.timing.date,
            time: this.timing.time
          })
          const data = {
            todoId: this.id,
            type: 1,
            contactId: this.user[0].id,
            timingRemind: imList
          }
          update(data).then(res => {
          })
        } else {
          const repeat = this.repeats.repeat
          const endRepeat = this.repeats.endRepeat
          const data = {
            todoId: this.id,
            type: 2,
            contactId: this.user[0].id,
            cycleRemind: {
              content: this.message,
              day: this.timing.date,
              time: this.timing.time,
              repeat,
              endRepeat
            }
          }
          update(data).then(res => {
          })
        }
        this.$router.back()
        this.$message.success('修改成功')
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.edit = true
      this.meeting = false
      this.id = this.$route.query.id
      this.editShow()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}

.index {
  font-size: 14px;
  min-height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .content {
    .details-title {
      color: rgba(0, 0, 0, .45);
      margin: -8px 37px;
      margin-top: -5px;
      padding-bottom: 6px;
      font-size: 12px;
    }

    img {
      width: 22px;
      height: 22px;
    }

    padding: 12px 16px;

    .tabs {
      display: flex;
      align-items: center;
      width: 98px;
      justify-content: space-between;
      margin-left: 21px;

      p {
        width: 23px;
        white-space: nowrap;
        text-align: center;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid transparent;
      }

      .tabs-active {
        border-bottom: 3px solid #1890ff;
        font-weight: bolder;
      }
    }

    .tips {
      background: #fef5de;
      padding: 5px 10px;
      border-radius: 9px;
      margin-top: 10px;

      img {
        height: auto !important;
      }
    }

    .field {
      margin-top: 10px;
      background: #fff;
      border-radius: 7px;

      .add-user {
        background: #f9fafe;
        height: 35px;
        display: flex;
        align-items: center;
        margin: 0 14px;
      }
    }

    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 14px;
      margin-top: -5px;
      padding-bottom: 10px;
    }

    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 14px;
    }

    .addTips {
      color: #1890ff;
      width: 100%;
      text-align: center;
      margin: 10px 0;
    }

    .insert {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #f2f2f2;
      margin: 0 14px;
      height: 40px;

      div {
        flex: 1;
        color: #cccccc;
        text-align: center;
        height: 100%;
        line-height: 40px
      }

      div:nth-child(1) {
        border-right: 1px solid #f2f2f2;
      }
    }
  }


}
</style>
