import request from '@/utils/request'

/**
 * 客户跟进
 */
export class ContactFollowApi {
  // 跟进状态列表
  static stageIndex (params) {
    return request({
      url: '/contactFollow/stageIndex',
      method: 'get',
      params: params
    })
  }

  // 编辑跟进状态
  static stageUpdate (params) {
    return request({
      url: '/contactFollow/stageUpdate',
      method: 'put',
      data: params
    })
  }

  // 添加跟进
  static store (params) {
    return request({
      url: '/contactFollow/store',
      method: 'post',
      data: params
    })
  }

  // 跟进模板-列表
  static templateIndex (params) {
    return request({
      url: '/contactFollow/templateIndex',
      method: 'get',
      params
    })
  }

  // 跟进模板-添加
  static templateStore (params) {
    return request({
      url: '/contactFollow/templateStore',
      method: 'post',
      data: params
    })
  }

  // 跟进模板-修改
  static templateUpdate (params) {
    return request({
      url: '/contactFollow/templateUpdate',
      method: 'put',
      data: params
    })
  }

  // 跟进模板-删除
  static templateDelete (params) {
    return request({
      url: '/contactFollow/templateDelete',
      method: 'delete',
      data: params
    })
  }

  //  跟进模板详情
  static infoApi (params) {
    return request({
      url: '/contactFollow/info',
      method: 'get',
      params
    })
  }

  //  跟进模板修改
  static updateApi (params) {
    return request({
      url: '/contactFollow/update',
      method: 'put',
      data: params
    })
  }

  // 等级列表
  static stageList (params) {
    return request({
      url: '/contactFollow/stageList',
      method: 'get',
      params
    })
  }

  // 待跟进客户列表
  static waitList (params) {
    return request({
      url: '/contactFollow/waitList',
      method: 'get',
      params
    })
  }

  // 待跟进客户列表3条
  static waitListThree (params) {
    return request({
      url: '/contactFollow/waitListThree',
      method: 'get',
      params
    })
  }
  
}
